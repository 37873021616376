import { Tooltip } from "@mui/material";
import { AttendanceEntry, Person, PersonCategory } from "src/types";

import "../style.scss";

const personCategory = (attendance: AttendanceEntry) => {
  return (attendance.personCategory ||
    attendance.person.category) as PersonCategory;
};

const PassCountsPanel = (props: { attendances: AttendanceEntry[] }) => {
  // only shown in admin
  const counts: Record<string, Set<string>> = {};
  const addCount = (passName: string, personName: string) => {
    if (passName in counts) {
      counts[passName].add(personName);
    } else {
      counts[passName] = new Set([personName]);
    }
  };

  props.attendances.forEach((attendance) => {
    const personName = attendance.person.name || "Unknown";
    if (attendance.pass_name) {
      addCount(attendance.pass_name, personName);
    } else {
      const cat = personCategory(attendance);

      if (cat?.isStaff) {
        addCount(cat.name || "Staff", personName);
      } else if (cat?.name) {
        addCount(`Unpaid (${cat.name})`, personName);
      } else {
        addCount("Unpaid", personName);
      }
    }
  });

  return (
    <div class="p-1">
      <div>
        <strong>Entry</strong>
      </div>
      {Object.keys(counts)
        .sort((a, b) => counts[b].size - counts[a].size)
        .map((pass) => (
          <Tooltip
            enterTouchDelay={100}
            leaveTouchDelay={5000}
            title={Array.from(counts[pass]).sort().join(", ")}
          >
            <div>
              {counts[pass].size}: {pass}
            </div>
          </Tooltip>
        ))}
    </div>
  );
};

const PronounCountPanel = (props: { attendances: AttendanceEntry[] }) => {
  let total = 0;
  const counts: Record<string, Record<string, Set<string>>> = {};

  const addCount = (person: Person, category: PersonCategory) => {
    if (category?.nonParticipant) {
      return;
    }
    const pronoun = person.pronoun || "";
    const personName = person.name || "Unknown";
    const isStaff = category?.isStaff || false;
    if (!(pronoun in counts)) {
      counts[pronoun] = {
        Total: new Set(),
        Student: new Set(),
        Staff: new Set(),
      };
    }
    ++total;
    counts[pronoun]["Total"].add(personName);
    counts[pronoun][isStaff ? "Staff" : "Student"].add(personName);
  };

  props.attendances.forEach((attendance) =>
    addCount(attendance.person, personCategory(attendance))
  );

  return (
    <div class="p-1">
      <div>
        <strong>Attendees ({total} total)</strong>
      </div>
      {Object.keys(counts).map((pronoun) => (
        <div>
          <strong>{pronoun}:</strong>{" "}
          {Object.keys(counts[pronoun]).map((subset) => (
            <Tooltip
              enterTouchDelay={100}
              leaveTouchDelay={5000}
              title={Array.from(counts[pronoun][subset]).sort().join(", ")}
            >
              <span>
                {subset}: {counts[pronoun][subset].size},{" "}
              </span>
            </Tooltip>
          ))}
        </div>
      ))}
    </div>
  );
};

const CategoryCountPanel = (props: { attendances: AttendanceEntry[] }) => {
  const counts: Record<string, Set<string>> = {};

  const addCount = (person: Person, category: string) => {
    const personName = person.name || "Unknown";
    if (category in counts) {
      counts[category].add(personName);
    } else {
      counts[category] = new Set([personName]);
    }
  };

  props.attendances.forEach((attendance) =>
    addCount(attendance.person, personCategory(attendance)?.name || "unknown")
  );

  return (
    <div class="p-1">
      <div>
        <strong>Category</strong>
      </div>
      {Object.keys(counts)
        .sort((a, b) => counts[b].size - counts[a].size)
        .map((category) => (
          <Tooltip
            enterTouchDelay={100}
            leaveTouchDelay={5000}
            title={Array.from(counts[category]).sort().join(", ")}
          >
            <div>
              {counts[category].size}: {category}
            </div>
          </Tooltip>
        ))}
    </div>
  );
};

const attendanceGroup = (attendance: AttendanceEntry) => {
  const category = personCategory(attendance);
  if (category?.isStaff) {
    return "Staff";
  } else if (category?.nonParticipant) {
    return "Non-Dancer (child/spectator)";
  } else if (!attendance.pass_name) {
    return "Payment Pending";
  } else if (attendance.is_present) {
    return "Paid and Present";
  } else {
    return "Absent";
  }
};

const strToKebab = (input: string) =>
  input.toLocaleLowerCase().replace(/\W/g, "-");

const PresentCountPanel = (props: { attendances: AttendanceEntry[] }) => {
  const counts: Record<string, Set<string>> = {};

  const addCount = (attendance: AttendanceEntry) => {
    const personName = attendance.person.name || "Unknown";
    const label = attendanceGroup(attendance);
    if (label in counts) {
      counts[label].add(personName);
    } else {
      counts[label] = new Set([personName]);
    }
  };

  props.attendances.forEach((attendance) => addCount(attendance));

  return (
    <div class="p-1">
      {Object.keys(counts)
        .sort((a, b) => counts[b].size - counts[a].size)
        .map((category) => (
          <Tooltip
            enterTouchDelay={100}
            leaveTouchDelay={5000}
            title={Array.from(counts[category]).sort().join(", ")}
          >
            <div class={strToKebab(category)}>
              {counts[category].size} {category}
            </div>
          </Tooltip>
        ))}
    </div>
  );
};

export function AttendancePanel(
  attendances: AttendanceEntry[],
  detailed = false,
  selectPerson?: (personId: string) => void
) {
  const personName = (person: Person) => {
    const personId = person.id as string;
    if (selectPerson && personId) {
      return <a onClick={() => selectPerson(personId)}>{person.name}</a>;
    }
    return <span>{person.name}</span>;
  };
  const header = () => {
    const col1 = () => {
      if (detailed) {
        return <PassCountsPanel attendances={attendances} />;
      }
      return <PronounCountPanel attendances={attendances} />;
    };
    const col2 = () => {
      if (detailed) {
        return (
          <>
            <PronounCountPanel attendances={attendances} />
            <CategoryCountPanel attendances={attendances} />
          </>
        );
      }
      return <PresentCountPanel attendances={attendances} />;
    };
    return (
      <div class="row row-10">
        <div class="col">{col1()}</div>
        <div class="col">{col2()}</div>
      </div>
    );
  };
  const centre = () => {
    const attendanceItem = (attendance: AttendanceEntry) => {
      const label = attendanceGroup(attendance);
      return (
        <div
          class={"item " + strToKebab(label)}
          title={attendance.pass_name || label}
        >
          {personName(attendance.person)}
          {attendance.pass_code && ` (${attendance.pass_code})`}
        </div>
      );
    };
    // TODO: if no attendances yet, allow fast sign-in of recently-attended staff?
    return (
      <div class="auto-column p-1">
        {attendances
          .sort((a, b) =>
            (a.person.name || "").localeCompare(b.person.name || "")
          )
          .map(attendanceItem)}
      </div>
    );
  };
  const footer = () => {
    const people = attendances.map((attendance) => attendance.person);

    const excludePhotos = people
      .filter((person) => person.photo_permission !== true)
      .map((person) => (
        <span class="item">
          {personName(person)}
          {person.photo_permission !== false && " (?)"}{" "}
        </span>
      ));

    const experienceBucket = (person: Person) => {
      const attendances = person.attendances || 0;
      if (attendances <= 6) {
        return "1-6 classes";
      }
      if (attendances <= 12) {
        return "7-12 classes";
      }
    };

    const experienceBuckets: Record<
      string,
      { names: JSX.Element[]; pronouns: Record<string, number> }
    > = {};
    people.forEach((person) => {
      const bucket = experienceBucket(person);
      if (!bucket) {
        return;
      }
      const label = (
        <span class="item">
          {personName(person)}&nbsp;({person.attendances}){" "}
        </span>
      );
      if (bucket in experienceBuckets) {
        experienceBuckets[bucket].names.push(label);
      } else {
        experienceBuckets[bucket] = { names: [label], pronouns: {} };
      }
      const pronoun = person.pronoun || "?";
      if (pronoun in experienceBuckets[bucket].pronouns) {
        experienceBuckets[bucket].pronouns[pronoun]++;
      } else {
        experienceBuckets[bucket].pronouns[pronoun] = 1;
      }
    });

    return (
      <>
        {excludePhotos.length > 0 && (
          <div class="p-1 no-video">
            <strong>Avoid in photos/videos:</strong>
            <br />
            {excludePhotos}
          </div>
        )}
        {Object.keys(experienceBuckets) && (
          <div class="p-1">
            <strong>Newer Dancers:</strong>
            <br />
            {Object.keys(experienceBuckets)
              .sort()
              .map((bucket) => (
                <div>
                  <strong>{bucket}</strong> (
                  {Object.entries(experienceBuckets[bucket].pronouns)
                    .map(([pronoun, count]) => count.toString() + " " + pronoun)
                    .join(", ")}
                  ): {experienceBuckets[bucket].names}
                </div>
              ))}
          </div>
        )}
      </>
    );
  };

  return (
    <div id="attendance_panel">
      {header()}
      {centre()}
      {footer()}
    </div>
  );
}
