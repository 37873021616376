import {
  Attendance,
  AttendanceEntry,
  CashBoxMixin,
  Pass,
  PaymentMethods,
  Person,
  PersonCategory,
  PersonSource,
  Sale,
  docLookup,
} from "../../../../types";
import {
  FormTabRenderer,
  FormTabRendererProps,
} from "../../../../components/forms/table";
import {
  DocumentReference,
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "preact/hooks";
import { db } from "../../../../firebase";
import { AttendancePanel } from "../../../door/components/attendance";
import { getCollection } from "../../../../utils/firebase";
import {
  getItemNames,
  saleItemNamesString,
} from "../../../door/components/person_sales";
import { sameDate, timeBucket, timeFormat } from "../../../../utils/datetime";
import {
  refundSale,
  removeAttendance,
  unUsePass,
  unUsePrebookedPass,
} from "../../../../utils/door";
import {
  Button,
  Input,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextareaAutosize,
} from "@mui/material";
import {
  GoogleMap,
  GoogleMapApiLoader,
  HeatmapLayer,
} from "react-google-map-wrapper";

const attendanceCache: Record<string, Promise<AttendanceEntry[]>> = {};

interface ChangedCashBox extends CashBoxMixin {
  changed: boolean;
}

export interface AttendanceCounts {
  total: number;
  distinct: number;
  new: number;
  names: string[];
  newNames: string[];
}

export const attendanceEntrySourceCounts = (
  eventAttendances: AttendanceEntry[]
) => {
  const renderAddress = (person: Person) => {
    const fields: (keyof Partial<Person>)[] = [
      "postal_address",
      "suburb",
      "postcode",
    ];
    return fields
      .map((field) => person[field])
      .filter((value) => value)
      .join(", ");
  };

  const sourceCounts: Record<string, AttendanceCounts> = {};
  const locationCounts: Record<string, AttendanceCounts> = {};

  const attendancesByPerson: Record<
    string,
    { count: number; person: Person; source: string; location: string }
  > = {};

  eventAttendances.forEach((attendance) => {
    if (!(attendance.person.id && attendance.person.name)) {
      return;
    }
    if (attendance.person.id in attendancesByPerson) {
      attendancesByPerson[attendance.person.id].count++;
    } else {
      const source =
        (attendance.person.source as PersonSource)?.name || "Unknown";
      const location = renderAddress(attendance.person);
      attendancesByPerson[attendance.person.id] = {
        count: 1,
        person: attendance.person,
        source,
        location,
      };
    }
  });

  Object.values(attendancesByPerson)
    .sort((a, b) => (a.person.name || "?").localeCompare(b.person.name || "?"))
    .forEach((record) => {
      const name = record.person.name || "?";
      if (record.source in sourceCounts) {
        sourceCounts[record.source].distinct++;
        sourceCounts[record.source].names.push(name);
        sourceCounts[record.source].total += record.count;
      } else {
        sourceCounts[record.source] = {
          distinct: 1,
          names: [name],
          new: 0,
          newNames: [],
          total: record.count,
        };
      }
      if (record.location in locationCounts) {
        locationCounts[record.location].distinct++;
        locationCounts[record.location].names.push(name);
        locationCounts[record.location].total += record.count;
      } else {
        locationCounts[record.location] = {
          distinct: 1,
          names: [name],
          new: 0,
          newNames: [],
          total: record.count,
        };
      }
      if (record.count >= (record.person.attendances || record.count)) {
        sourceCounts[record.source].new++;
        sourceCounts[record.source].newNames.push(name);
        locationCounts[record.location].new++;
        locationCounts[record.location].newNames.push(name);
      }
    });
  return { sourceCounts, locationCounts };
};

const getAttendanceForEvent = (
  eventPath: string
): Promise<AttendanceEntry[]> => {
  if (!(eventPath in attendanceCache)) {
    attendanceCache[eventPath] = new Promise((resolve) => {
      const resolveAttendance = (
        attendanceRefs: Attendance[],
        personPass: docLookup
      ) => {
        return attendanceRefs.map((attendance) => {
          return {
            ...attendance,
            person: personPass[attendance.person.path],
          } as AttendanceEntry;
        });
      };

      getCollection("categories", true).then((resultList) => {
        const personCategories = Object.fromEntries(
          resultList.map((result) => [result.collectionPath, result])
        );
        getCollection("sources", true).then((resultList) => {
          const personSources = Object.fromEntries(
            resultList.map((result) => [result.collectionPath, result])
          );

          const name = eventPath + "/attendance/";
          getDocs(query(collection(db, name))).then((snapshot) => {
            const attendanceRefs = snapshot.docs.map((doc) => {
              return {
                id: doc.id,
                collectionPath: doc.ref.path,
                ...doc.data(),
              } as Attendance;
            });

            const peopleIds = attendanceRefs
              .filter((attendance) => attendance.person)
              .map((attendance) => attendance.person.id);
            const passIds = attendanceRefs
              .filter((attendance) => attendance.pass?.id)
              .map((attendance) => attendance.pass?.id as string);

            Promise.all([
              ...peopleIds.map((personId) =>
                getDoc(doc(db, "person", personId))
              ),
              ...passIds.map((passId) => getDoc(doc(db, "pass-types", passId))),
            ]).then((personPassArr) => {
              const personPass: docLookup = Object.fromEntries(
                personPassArr.map((personPass) => {
                  const data =
                    ({
                      ...personPass.data(),
                      id: personPass.id,
                      collectionPath: personPass.ref.path,
                    } as Person) || [];
                  return [
                    personPass.ref.path,
                    {
                      ...data,
                      ...(data["category"]
                        ? {
                            category:
                              personCategories[
                                (
                                  data[
                                    "category"
                                  ] as DocumentReference<PersonCategory>
                                ).path
                              ],
                          }
                        : {}),

                      ...(data["source"]
                        ? {
                            source:
                              personSources[
                                (
                                  data[
                                    "source"
                                  ] as DocumentReference<PersonSource>
                                ).path
                              ],
                          }
                        : {}),
                    } || {},
                  ];
                })
              );
              resolve(resolveAttendance(attendanceRefs, personPass));
            });
          });
        });
      });
    });
  }

  return Promise.resolve(attendanceCache[eventPath]);
};

export function AdminAttendance(): FormTabRenderer {
  const [attendances, setAttendances] = useState<
    Record<string, AttendanceEntry[] | undefined>
  >({});

  return (props: FormTabRendererProps) => {
    if (!props.instance?.id) {
      return <></>;
    }
    const eventId = props.instance.id;
    if (!(eventId in attendances)) {
      setAttendances({ ...attendances, [eventId]: undefined });

      getAttendanceForEvent(`${props.props.dbCollection}/${eventId}`).then(
        (eventAttendances) => {
          setAttendances({
            ...attendances,
            [eventId]: eventAttendances,
          });
        }
      );
    }
    if (eventId in attendances) {
      const eventAttendances = attendances[eventId];
      if (eventAttendances) {
        return AttendancePanel(eventAttendances, true);
      }
    }
    return <div>Attendance tab loading</div>;
  };
}

export const ArrivalTimesTable = (
  eventAttendances: AttendanceEntry[],
  includeDates = false
) => {
  const ExcludeOptions = {
    none: "Include All",
    staff: "Exclude Staff",
    free: "Exclude Free & Staff",
  };

  const arrivedColour = "#ddfa";
  const presentColour = "#afaa";

  const [exclude, setExclude] = useState<keyof typeof ExcludeOptions>("none");

  const peopleFilter = (attendance: AttendanceEntry) => {
    const when = attendance.date.toDate();
    if (when.getMilliseconds() === 0 && when.getSeconds() === 0) {
      return false;
    }
    const personCat = attendance.person.category as PersonCategory | undefined;
    if (exclude === "staff" && personCat?.isStaff) {
      return false;
    }
    if (exclude === "free" && personCat?.freeEntry) {
      return false;
    }
    return true;
  };

  const byTimeBucket: Record<
    string,
    { names: string[]; count: number; cumulativeCount: number }
  > = {};
  let cumulative = 0;
  eventAttendances
    .filter(peopleFilter)
    .map((attendance) => {
      return { ...attendance, bucket: timeBucket(attendance.date) };
    })
    .sort((a, b) => a.date.seconds - b.date.seconds)
    .sort((a, b) =>
      (a.person?.name || "?").localeCompare(b.person?.name || "?")
    )
    .sort((a, b) => a.bucket.localeCompare(b.bucket))
    .forEach((attendance) => {
      const bucket = attendance.bucket;
      const name =
        (attendance.person?.name || "?") +
        " (" +
        timeFormat(attendance.date.toDate(), includeDates) +
        ")";
      ++cumulative;
      if (bucket in byTimeBucket) {
        byTimeBucket[bucket].names.push(name);
        byTimeBucket[bucket].count += 1;
        byTimeBucket[bucket].cumulativeCount = cumulative;
      } else {
        byTimeBucket[bucket] = {
          names: [name],
          count: 1,
          cumulativeCount: cumulative,
        };
      }
    });

  const gradient = (bucket: keyof typeof byTimeBucket) => {
    const arrived = (100 * byTimeBucket[bucket].count) / cumulative;
    const present = (100 * byTimeBucket[bucket].cumulativeCount) / cumulative;
    return `linear-gradient(to right, ${arrivedColour} 0%, ${arrivedColour} ${arrived}%, ${presentColour} ${arrived}%, ${presentColour} ${present}%, #00000000 ${present}%)`;
  };

  return (
    <>
      <Select
        value={exclude}
        onChange={(e: SelectChangeEvent) =>
          setExclude(
            (e.target as { value: keyof typeof ExcludeOptions } | undefined)
              ?.value as keyof typeof ExcludeOptions
          )
        }
      >
        {Object.entries(ExcludeOptions).map(([value, label]) => (
          <MenuItem value={value}>{label}</MenuItem>
        ))}
      </Select>
      <table>
        <thead>
          <tr>
            <th>Time</th>
            <th>#&nbsp;People</th>
            <th>Who</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(byTimeBucket).map((bucket) => {
            const count = byTimeBucket[bucket].count;
            const collapse = count >= 5;
            const peopleNames = byTimeBucket[bucket].names.join(", ");
            return (
              <tr style={{ backgroundImage: gradient(bucket) }}>
                <th>{bucket}</th>
                <td>{count}</td>
                <td>
                  {collapse ? (
                    <details>
                      <summary>Click to show {count} names</summary>
                      {peopleNames}
                    </details>
                  ) : (
                    peopleNames
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export const SourcesTable = (
  sourceCounts: Record<string, AttendanceCounts>
) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Source</th>
          <th>New People</th>
          <th>Different People</th>
          <th>All Attendances</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(sourceCounts)
          .sort()
          .map(([source, counts]) => (
            <tr>
              <th>{source}</th>
              <td>
                {(counts.new > 0 && (
                  <details>
                    <summary>{counts.new}</summary>
                    {counts.newNames.join(", ")}
                  </details>
                )) ||
                  0}
              </td>
              <td>
                <details>
                  <summary>{counts.distinct}</summary>
                  {counts.names.join(", ")}
                </details>
              </td>
              <td>{counts.total}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export const LocationsTable = (
  locationCounts: Record<string, AttendanceCounts>
) => {
  const [showMap, setShowMap] = useState<boolean>(false);

  const map_key = "AIzaSyCUm8oUjIGF8E0lazKIIn596Tu8-aJNxgg";
  const MapContent = () => {
    const [markers, setMarkers] = useState<
      { location: google.maps.LatLng; weight: number }[]
    >([]);

    const geocoder = new google.maps.Geocoder();

    if (!markers.length) {
      Promise.allSettled(
        Object.entries(locationCounts)
          .filter(([name]) => name)
          .map(([address, data]) => {
            geocoder.geocode({ address }, (results, status) => {
              if (results && status === "OK") {
                const newMarker = {
                  location: results[0].geometry.location,
                  weight: data.total,
                };
                setMarkers((markers) => {
                  return [...markers, newMarker];
                });
              } else {
                console.warn("failed geocode for " + address + ": " + status);
              }
            });
          })
      );
      return <></>;
    }

    const data = markers?.map((marker) => {
      return { ...marker, location: new google.maps.LatLng(marker.location) };
    });

    return <HeatmapLayer data={data} radius={35} />;
  };

  return (
    <>
      {(showMap && (
        <GoogleMapApiLoader apiKey={map_key} region="au">
          <GoogleMap
            initialZoom={11}
            center={{ lat: -34.931599876151175, lng: 138.60088617997877 }}
            style={{ height: "800px", maxHeight: "85vh", margin: "1rem 0" }}
          >
            <MapContent />
          </GoogleMap>
        </GoogleMapApiLoader>
      )) || <Button onClick={() => setShowMap(true)}>Load Map</Button>}
      <table>
        <thead>
          <tr>
            <th>Address</th>
            <th>New People</th>
            <th>Different People</th>
            <th>All Attendances</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(locationCounts)
            .sort()
            .map(([location, counts]) => (
              <tr>
                <th>{location}</th>
                <td>
                  {(counts.new > 0 && (
                    <details>
                      <summary>{counts.new}</summary>
                      {counts.newNames.join(", ")}
                    </details>
                  )) ||
                    0}
                </td>
                <td>
                  <details>
                    <summary>{counts.distinct}</summary>
                    {counts.names.join(", ")}
                  </details>
                </td>
                <td>{counts.total}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export function AdminAttendanceSources(): FormTabRenderer {
  const [attendances, setAttendances] = useState<
    Record<string, Record<string, AttendanceCounts>>
  >({});

  return (props: FormTabRendererProps) => {
    if (!props.instance?.id) {
      return <></>;
    }
    const eventId = props.instance.id;
    if (!(eventId in attendances)) {
      setAttendances({ ...attendances, [eventId]: {} });

      getAttendanceForEvent(`${props.props.dbCollection}/${eventId}`).then(
        (eventAttendances) => {
          setAttendances({
            ...attendances,
            [eventId]:
              attendanceEntrySourceCounts(eventAttendances).sourceCounts,
          });
        }
      );
    }
    if (eventId in attendances) {
      const eventAttendances = attendances[eventId];
      if (eventAttendances) {
        return SourcesTable(eventAttendances);
      }
    }
    return <div>Sources tab loading</div>;
  };
}

export function AdminAttendanceLocations(): FormTabRenderer {
  const [attendances, setAttendances] = useState<
    Record<string, Record<string, AttendanceCounts>>
  >({});

  return (props: FormTabRendererProps) => {
    if (!props.instance?.id) {
      return <></>;
    }
    const eventId = props.instance.id;
    if (!(eventId in attendances)) {
      setAttendances({ ...attendances, [eventId]: {} });

      getAttendanceForEvent(`${props.props.dbCollection}/${eventId}`).then(
        (eventAttendances) => {
          setAttendances({
            ...attendances,
            [eventId]:
              attendanceEntrySourceCounts(eventAttendances).locationCounts,
          });
        }
      );
    }
    if (eventId in attendances) {
      const eventAttendances = attendances[eventId];
      if (eventAttendances) {
        return LocationsTable(eventAttendances);
      }
    }
    return <div>Locations tab loading</div>;
  };
}

export function AdminAttendanceTimes(): FormTabRenderer {
  const [attendances, setAttendances] = useState<
    Record<string, AttendanceEntry[] | undefined>
  >({});

  return (props: FormTabRendererProps) => {
    if (!props.instance?.id) {
      return <></>;
    }
    const eventId = props.instance.id;
    if (!(eventId in attendances)) {
      setAttendances({ ...attendances, [eventId]: undefined });

      getAttendanceForEvent(`${props.props.dbCollection}/${eventId}`).then(
        (eventAttendances) => {
          setAttendances({
            ...attendances,
            [eventId]: eventAttendances,
          });
        }
      );
    }
    if (eventId in attendances) {
      const eventAttendances = attendances[eventId];
      if (eventAttendances) {
        return ArrivalTimesTable(eventAttendances);
      }
    }
    return <div>Attendance tab loading</div>;
  };
}

export function AdminAttendanceSales(): FormTabRenderer {
  // TODO: show cashbox & notes fields
  const [salesByEvent, setSalesByEvent] = useState<
    Record<string, Sale[] | undefined>
  >({});
  const [itemNames, setItemNames] = useState<Record<string, string>>();
  const [attendances, setAttendances] = useState<
    Record<string, Record<string, AttendanceEntry> | undefined>
  >({});

  const resolvePerson = (
    saleRefs: Sale[],
    personLookup: Record<string, string>
  ) => {
    return saleRefs.map((sale) => {
      return {
        ...sale,
        person: sale.person ? personLookup[sale.person.path] : sale.person,
      } as Sale;
    });
  };

  useEffect(() => {
    if (!itemNames) {
      getItemNames().then((itemNames) => setItemNames(itemNames));
    }
  });

  const saleItemNamesPersonString = (
    sale: Sale,
    itemNames: Record<string, string>
  ) => {
    if (sale.person) {
      return (
        <>
          {saleItemNamesString(sale, itemNames)} -- {sale.person}
        </>
      );
    }
    return saleItemNamesString(sale, itemNames);
  };

  return (props: FormTabRendererProps) => {
    if (!props.instance?.id || !itemNames || !attendances) {
      return <></>;
    }
    const eventId = props.instance.id;
    const eventCashBox: CashBoxMixin = {
      notes: (props.instance as CashBoxMixin).notes,
      cashbox: (props.instance as CashBoxMixin).cashbox,
    };
    const [cashBox, setCashBox] = useState<ChangedCashBox>({
      ...eventCashBox,
      changed: false,
    });

    if (!(eventId in attendances)) {
      setAttendances({ ...attendances, [eventId]: undefined });

      getAttendanceForEvent(`${props.props.dbCollection}/${eventId}`).then(
        (eventAttendances) => {
          setAttendances({
            ...attendances,
            [eventId]: Object.fromEntries(
              eventAttendances.map((attendance) => [
                attendance.pass?.id,
                attendance,
              ])
            ),
          });
        }
      );
    }

    if (!(eventId in salesByEvent)) {
      setSalesByEvent({ ...salesByEvent, [eventId]: undefined });

      getDocs(
        query(
          collection(db, "sale"),
          where("event", "==", doc(db, props.props.dbCollection, eventId))
        )
      ).then((snapshot) => {
        const sales = snapshot.docs.map((doc) => {
          return { id: doc.id, collectionPath: doc.ref.path, ...doc.data() };
        }) as Sale[];
        const peopleIds = sales
          .filter((sale) => sale.person?.id)
          .map((sale) => sale.person?.id || "");

        Promise.all(
          peopleIds.map((personId) => getDoc(doc(db, "person", personId)))
        ).then((personArr) => {
          const personLookup = Object.fromEntries(
            personArr.map((personPass) => [
              personPass.ref.path,
              (personPass.data() || {})["name"],
            ])
          );
          setSalesByEvent({
            ...salesByEvent,
            [eventId]: resolvePerson(sales, personLookup),
          });
        });
      });
    }

    const totalsPerMethod: Partial<
      Record<keyof typeof PaymentMethods, number>
    > = {};
    const renderSale = (
      sale: Sale,
      eventAttendances?: Record<string, AttendanceEntry>
    ) => {
      if (sale.refunded) {
        return (
          <div class="row disabled">
            <div class="row">{saleItemNamesPersonString(sale, itemNames)}</div>
            <div class="row f-grow align-end">
              Refunded:{" "}
              {PaymentMethods[sale.method as keyof typeof PaymentMethods]}
              &nbsp; ${sale.amount}
            </div>{" "}
            {sale.notes && <div class="row">{sale.notes}</div>}
          </div>
        );
      }
      if (
        sale.method in totalsPerMethod &&
        !(totalsPerMethod[sale.method] === undefined)
      ) {
        (totalsPerMethod[sale.method] as number) += sale.amount;
      } else {
        totalsPerMethod[sale.method] = sale.amount;
      }

      const saleMethod = () => {
        const setMethod = (method: keyof typeof PaymentMethods) => {
          updateDoc(doc(db, sale.collectionPath as string), { method })
            .then(() => {
              setSalesByEvent((salesByEvent) => {
                return {
                  ...salesByEvent,
                  [eventId]: salesByEvent[eventId]?.map((allSales) =>
                    allSales.id === sale.id ? { ...allSales, method } : allSales
                  ),
                };
              });
            })
            .catch(alert);
        };

        return (
          <select
            value={sale.method}
            onChange={(e) =>
              setMethod(e.currentTarget.value as keyof typeof PaymentMethods)
            }
          >
            {Object.keys(PaymentMethods).map((method) => (
              <option value={method}>
                {PaymentMethods[method as keyof typeof PaymentMethods]}
              </option>
            ))}
          </select>
        );
      };

      const saleTime = () => {
        const asDate = sale.time.toDate();
        const isSameDay = () => {
          if (!props.instance) {
            return;
          }
          if ("date" in props.instance) {
            const eventDate = (props.instance?.date as Timestamp).toDate();
            return sameDate(eventDate, asDate);
          }
          if ("start" in props.instance) {
            const start = (props.instance?.start as Timestamp).toDate();
            if ("end" in props.instance) {
              const end = (props.instance?.end as Timestamp).toDate();
              if (!sameDate(start, end)) {
                // multi-day event, always show date
                return false;
              }
            }
            return sameDate(start, asDate);
          }
        };
        if (
          asDate.getUTCHours() === 0 &&
          asDate.getUTCMinutes() === 0 &&
          asDate.getUTCSeconds() === 0
        ) {
          // No time stored, it came from old DB import
          return;
        }
        return timeFormat(sale.time.toDate(), !isSameDay());
      };

      const refund = (sale: Sale) => {
        const salePasses = sale.lines
          .map((line) => line.pass?.path)
          .filter((passPath) => passPath !== undefined);

        const updateAttendances =
          eventAttendances &&
          Object.fromEntries(
            Object.entries(eventAttendances)
              .filter(
                ([_, attendance]) =>
                  salePasses.indexOf(
                    (attendance.pass as DocumentReference<Pass>)?.path
                  ) >= 0
              )
              .map(([id, attendance]) => {
                return [
                  id,
                  Object.fromEntries(
                    Object.entries(attendance).filter(
                      ([key, _]) =>
                        [
                          "pass",
                          "pass_code",
                          "pass_is_multiuse",
                          "pass_name",
                        ].indexOf(key) < 0
                    )
                  ) as AttendanceEntry,
                ];
              })
          );

        const prompt =
          "Refund sale to " +
          sale.person +
          "?\nAttendances will become unpaid (may be removed below)";

        if (confirm(prompt)) {
          refundSale({ sale })
            .then(() => {
              setSalesByEvent((salesByEvent) => {
                return {
                  ...salesByEvent,
                  [eventId]: salesByEvent[eventId]?.map((allSales) =>
                    allSales.id === sale.id
                      ? { ...allSales, refunded: true }
                      : allSales
                  ),
                };
              });
              setAttendances((attendances) => {
                return {
                  ...attendances,
                  [eventId]: { ...attendances[eventId], ...updateAttendances },
                };
              });
            })
            .catch(alert);
        }
      };

      return (
        <details key={sale.id}>
          <summary class="row">
            <div>{saleItemNamesPersonString(sale, itemNames)}</div>
            <div class="f-grow align-end">
              {PaymentMethods[sale.method as keyof typeof PaymentMethods]}
              &nbsp; ${sale.amount}
            </div>
          </summary>
          {sale.notes && <div class="row">{sale.notes}</div>}
          <div class="row">
            <div>
              <button onClick={() => refund(sale)}>Refund Sale</button>
            </div>
            <div>{saleTime()}</div>
            <div class="f-grow align-end">{saleMethod()}</div>
          </div>
        </details>
      );
    };
    const saleSort = (a: Sale, b: Sale) => {
      if (a.method === b.method) {
        return b.amount - a.amount;
      }
      return a.method.toString().localeCompare(b.method.toString());
    };

    const renderEntry = (entry: AttendanceEntry) => {
      const remove = () => {
        const personId = entry.person.id as string;
        const isClass = props.props.dbCollection === "class-nights";
        const unUseProps = {
          [isClass ? "classId" : "eventId"]: eventId,
          personId,
        };

        if (confirm("Remove attendance?")) {
          const getPromise = () => {
            if (entry.pass_is_multiuse) {
              if (isClass) {
                if (entry.pass && "firestore" in entry.pass) {
                  return getDoc(entry.pass).then((pass) =>
                    unUsePass({
                      ...unUseProps,
                      pass: {
                        ...pass.data(),
                        id: pass.id,
                        collectionPath: pass.ref.path,
                      } as Pass,
                    })
                  );
                } else if (entry.pass && "collectionPath" in entry.pass) {
                  return unUsePass({ ...unUseProps, pass: entry.pass });
                }
                return Promise.reject("Pass is unknown, unable to unuse");
              } else {
                return unUsePrebookedPass({ ...unUseProps, attendance: entry });
              }
            } else {
              return removeAttendance(unUseProps);
            }
          };
          getPromise()
            .then(() => {
              setAttendances((attendances) => {
                return {
                  ...attendances,
                  [eventId]: Object.fromEntries(
                    Object.entries(attendances[eventId] || {}).filter(
                      ([, v]) => v.id !== entry.id
                    )
                  ),
                };
              });
            })
            .catch(alert);
        }
      };

      return (
        <details key={entry.id}>
          <summary class="row">
            <div class={entry.pass_name ? undefined : "payment-pending"}>
              {entry.pass_name || "Unpaid"}
              {entry.person.name && " -- " + entry.person.name}
            </div>
          </summary>
          <div class="row">
            <button onClick={remove}>
              {entry.pass_is_multiuse ? "Unuse Pass" : "Remove Attendance"}
            </button>
          </div>
        </details>
      );
    };
    const entrySort = (a: AttendanceEntry, b: AttendanceEntry) => {
      const aRestricted =
        a.person.category &&
        "restricted" in a.person.category &&
        a.person.category.restricted;
      const bRestricted =
        b.person.category &&
        "restricted" in b.person.category &&
        b.person.category.restricted;
      if (aRestricted && !bRestricted) {
        return 1;
      }
      if (!aRestricted && bRestricted) {
        return -1;
      }
      return (a.pass_name || "").localeCompare(b.pass_name || "");
    };

    if (eventId in salesByEvent && eventId in attendances) {
      const eventSales = salesByEvent[eventId];
      const eventAttendances = attendances[eventId];

      const renderSales = () => {
        if (eventSales) {
          return (
            <div class="p-1">
              <strong>Sales:</strong>
              {eventSales
                .sort(saleSort)
                .map((sale) => renderSale(sale, eventAttendances))}
            </div>
          );
        }
      };

      const passUses = () => {
        const multiuseAttendances = Object.values(eventAttendances || {})
          .filter((entry) => entry.pass_is_multiuse)
          .sort(entrySort);
        const restrictedAttendances = Object.values(eventAttendances || {})
          .filter(
            (entry) =>
              entry.person.category &&
              "restricted" in entry.person.category &&
              entry.person.category.restricted
          )
          .sort(entrySort);

        return (
          <div class="p-1">
            <strong>Entries</strong>
            {multiuseAttendances.map((entry) => renderEntry(entry))}
            <br />
            {restrictedAttendances.map((entry) => renderEntry(entry))}
          </div>
        );
      };

      const cashBoxNotes = () => {
        const cashDelta = () => {
          const delta = (cashBox?.cashbox || 0) - (totalsPerMethod.cash || 0);
          if (delta === 0) {
            return <span class="active">Cashbox matches</span>;
          }
          if (delta > 0) {
            return (
              <span class="alert">
                Cashbox <strong>over</strong> by ${delta}
              </span>
            );
          }
          return (
            <span class="alert">
              Cashbox <strong>under</strong> by ${-delta}
            </span>
          );
        };

        const setCashboxCash = (cash: string) => {
          setCashBox((cashBox) => {
            return { ...cashBox, changed: true, cashbox: parseInt(cash) || 0 };
          });
        };

        const setCashboxNotes = (notes: string) => {
          setCashBox((cashBox) => {
            return { ...cashBox, changed: true, notes };
          });
        };

        const saveCashbox = () => {
          updateDoc(
            doc(db, props.props.dbCollection, props.instance?.id as string),
            { cashbox: cashBox.cashbox, notes: cashBox.notes }
          ).catch(alert);
          setCashBox((cashBox) => {
            return { ...cashBox, changed: false };
          });
        };

        return (
          <div class="p-1">
            <div>
              <strong>Cashbox:</strong>
            </div>
            <div>
              $
              <Input
                type="number"
                value={cashBox?.cashbox}
                onChange={(e) => setCashboxCash(e.currentTarget.value)}
              ></Input>{" "}
              {cashDelta()}
            </div>
            <br />
            <div>
              <strong>Notes:</strong>
            </div>
            <div>
              <TextareaAutosize
                value={cashBox?.notes}
                onChange={(e) => setCashboxNotes(e.currentTarget.value)}
              />
            </div>
            <button disabled={!cashBox.changed} onClick={saveCashbox}>
              Update cashbox &amp; notes
            </button>
          </div>
        );
      };

      if (eventSales) {
        return (
          <div>
            {renderSales()}
            <div class="p-1">
              <strong>Payment Totals:</strong>
              {Object.keys(totalsPerMethod).map((method) => (
                <div class="row">
                  {PaymentMethods[method as keyof typeof PaymentMethods]}: $
                  {totalsPerMethod[method as keyof typeof PaymentMethods]}
                </div>
              ))}
            </div>
            {cashBoxNotes()}
            {passUses()}
          </div>
        );
      }
    }
    return <div>Sales tab loading</div>;
  };
}
