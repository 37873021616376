import { useEffect, useState } from "preact/hooks";

import {
  Attendance,
  AttendanceEntry,
  ClassNight,
  Person,
} from "../../../../types";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { db } from "../../../../firebase";
import { AdminClassesDefinition } from "../classes/classes";
import { getCollection } from "../../../../utils/firebase";
import {
  ArrivalTimesTable,
  attendanceEntrySourceCounts,
  LocationsTable,
  SourcesTable,
} from "../classes/attendance_base";
import { dateFormatAU } from "../../../../utils/datetime";
import { TabSet } from "../../../../components/forms/tabs";

interface ClassWithAttendees extends ClassNight {
  attendances: AttendanceEntry[];
}

export default function AdminReportRecentAttendances() {
  const classDefinition = AdminClassesDefinition();

  const [classes, setClasses] = useState<ClassWithAttendees[]>();
  // const [danceEvents, setDanceEvents] = useState<DanceEvent[]>();

  const since = new Date();
  since.setMonth(since.getMonth() - 3);

  useEffect(() => {
    getCollection("categories").then((categories) => {
      const categoryLookup = Object.fromEntries(
        categories.map((category) => [category.id, category])
      );
      getCollection("sources").then((sources) => {
        const sourceLookup = Object.fromEntries(
          sources.map((source) => [source.id, source])
        );
        getDocs(
          query(
            collection(db, classDefinition["dbCollection"]),
            ...classDefinition["displayOrder"],
            where("date", ">=", Timestamp.fromDate(new Date(since)))
          )
        ).then((querySnapshot) => {
          // attendance.person -> source, location, category

          const paths = querySnapshot.docs.map((snapDoc) => snapDoc.ref.path);
          Promise.all(
            paths.map(
              (path) =>
                getCollection(path + "/attendance", true) as Promise<
                  Attendance[]
                >
            )
          ).then((attendances) => {
            const peoplePaths = Array.from(
              new Set(
                attendances.flatMap((night) =>
                  night.flatMap((attendance) => attendance.person.path)
                )
              )
            );
            Promise.all(peoplePaths.map((path) => getDoc(doc(db, path)))).then(
              (peopleSnapshot) => {
                const peopleLookups = Object.fromEntries(
                  peopleSnapshot.map((snap) => {
                    const person = { ...snap.data(), id: snap.id } as Person;
                    person.category = categoryLookup[person.category?.id || ""];
                    person.source = sourceLookup[person.source?.id || ""];
                    return [snap.ref.path, person];
                  })
                );
                const classes = querySnapshot.docs.map((snapDoc, i) => {
                  const night = snapDoc.data();

                  return {
                    ...night,
                    attendances: attendances[i].map((attendance) => {
                      return {
                        ...attendance,
                        person: peopleLookups[attendance.person.path],
                      } as AttendanceEntry;
                    }),
                  } as ClassWithAttendees;
                });
                setClasses(classes);
              }
            );
          });
        });
      });
    });
  }, []);

  if (!classes) {
    return <></>;
  }

  const allAttendances = classes.flatMap(
    (classEntry) => classEntry.attendances
  );

  const counts = attendanceEntrySourceCounts(allAttendances);

  const tabs = {
    "Arrival Times": ArrivalTimesTable(allAttendances, true),
    Sources: SourcesTable(counts.sourceCounts),
    Locations: LocationsTable(counts.locationCounts),
  };

  return (
    <div class="app-body-wrap">
      <div class="app-body">
        <h2>Attendances Since {dateFormatAU(since)}</h2>
        <TabSet tabs={tabs} />
      </div>
    </div>
  );
}
