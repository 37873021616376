import {
  DocumentData,
  DocumentReference,
  Timestamp,
  addDoc,
  collection,
  collectionGroup,
  deleteDoc,
  deleteField,
  doc,
  getDocs,
  increment,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  ClassNight,
  Pass,
  DanceEvent,
  Person,
  Sale,
  PassType,
  Attendance,
  Stock,
  SaleLine,
  AvailableEventPrice,
  AttendanceEntry,
  PersonCategory,
} from "../types";
import { DOOR_PREFIX, db } from "../firebase";
import { castDoc } from "./utils";
import { TimestampMax, TimestampMaxYear } from "./types";

export interface PendingStockSales {
  qty?: number;
  item: Stock;
}
export interface PendingEntry {
  person: Person; // need ID to select, and name to display
  action: "buy" | "buyUse" | "checkIn" | "use" | "pending" | "unpaid";
  pass?: Pass;
  passType?: PassType;
  eventPrice?: AvailableEventPrice;
  attendance?: Attendance; //  presold
  // attending means buy without a pass
  committing?: boolean;
  error?: string;
  forEvent?: DanceEvent["id"];
}

export interface PassSalePropsBase {
  personId: Person["id"];
  classId?: ClassNight["id"];
  eventId?: DanceEvent["id"];
  forEvent?: DanceEvent["id"];
  public?: boolean;
  passTypes?: Record<string, PassType>;
  presale?: boolean;
}

interface UsePassProps extends PassSalePropsBase {
  pass: Pass | AvailableEventPrice;
  prebook?: boolean;
}

interface UsePrebookedPassProps extends PassSalePropsBase {
  attendance: Attendance | AttendanceEntry;
}

interface BuyPassProps extends PassSalePropsBase {
  pass: PassType | AvailableEventPrice;
  sale?: Sale;
  saleMethod?: Sale["method"];
  saleNotes?: Sale["notes"];
}

interface ApplyPendingProps {
  personId: Person["id"];
  classId?: ClassNight["id"];
  eventId?: DanceEvent["id"];
  forEvent?: DanceEvent["id"];
  pending: Omit<PendingEntry, "person">[];
  saleMethod: Sale["method"];
  passTypes: Record<string, PassType>;
}

const getCollectionPath = (props: PassSalePropsBase, ...path: string[]) => {
  if (props.forEvent) {
    return collection(db, "events", props.forEvent as string, ...path);
  }
  if (props.classId) {
    return collection(db, "class-nights", props.classId as string, ...path);
  }
  if (props.eventId) {
    return collection(db, "events", props.eventId as string, ...path);
  }
};
const getCollectionDoc = (props: PassSalePropsBase, ...path: string[]) => {
  if (props.forEvent) {
    return doc(db, "events", props.forEvent as string, ...path);
  }
  if (props.classId) {
    return doc(db, "class-nights", props.classId as string, ...path);
  }
  if (props.eventId) {
    return doc(db, "events", props.eventId as string, ...path);
  }
};

const clearScanRecord = (props: PassSalePropsBase) => {
  if (props.public) {
    // from door-public, keep scan records
    return;
  }
  if (props.presale) {
    // presale is for a different event, so scans doesn't make sense
    return;
  }
  const personId = props.personId as string;
  const scanRef = getCollectionDoc(
    props,
    DOOR_PREFIX + "barcode-scans",
    personId
  );
  if (scanRef) {
    updateDoc(scanRef, { hidden: true }).catch(() => {
      /* no document to update is no problem */
    });
  }
};

const updateAttendance = (personId: string, adjustBy = 1) => {
  updateDoc(doc(db, DOOR_PREFIX + "person/" + personId), {
    attendances: increment(adjustBy),
    last_attended: Timestamp.now(),
    last_update: Timestamp.now(),
  });
};
const incrementAttendance = (personId: string) => updateAttendance(personId, 1);
const decrementAttendance = (personId: string) =>
  updateAttendance(personId, -1);

export const unpaidAttendance = (
  props: PassSalePropsBase
): Promise<boolean> => {
  incrementAttendance(props.personId as string);
  const attendancePath = getCollectionPath(props, DOOR_PREFIX + "attendance");
  if (!attendancePath) {
    return Promise.reject("Need classId or eventId");
  }

  const attendance: Attendance = {
    is_present: true,
    date: Timestamp.now(),
    pass: null,
    event_ref: getCollectionDoc(props) as DocumentReference<ClassNight>,
    person: castDoc(DOOR_PREFIX + "person", props.personId as string),
  };
  return setDoc(doc(attendancePath, props.personId), attendance as object, {
    merge: true,
  })
    .then(() => Promise.resolve(true))
    .catch((reason) => Promise.reject(reason));
};

export const usePass = (props: UsePassProps): Promise<boolean> => {
  clearScanRecord(props);
  incrementAttendance(props.personId as string);
  const passDoc: DocumentReference<Pass> = castDoc(
    props.pass.collectionPath as string
  );

  const getPassUpdate = (): [Promise<void>, boolean] => {
    if (!("remaining_uses" in props.pass)) {
      return [Promise.resolve(), false];
    }
    const passUpdate: Partial<Pass> = {
      remaining_uses: props.pass.remaining_uses - 1,
    };
    if (!passUpdate.remaining_uses) {
      passUpdate.has_remaining_uses = false;
    }
    if (!props.pass.type?.path) {
      return [Promise.reject("Pass type not recognised"), false];
    }
    const passType = props.passTypes && props.passTypes[props.pass.type.path];
    if (!passType) {
      return [Promise.reject("Pass type not found"), false];
    }
    const expireYear = props.pass.expires.toDate().getFullYear();
    if (expireYear >= TimestampMaxYear || expireYear < 2000) {
      const expiry = new Date(Date.now() + 12 * 60 * 60 * 1000); // default to in 12 hours, so it won't expire during the class
      if (passType.valid_month) {
        expiry.setMonth(expiry.getMonth() + passType.valid_month);
      }
      if (passType.valid_week) {
        expiry.setDate(expiry.getDate() + passType.valid_week * 7);
      }
      passUpdate.expires = Timestamp.fromDate(expiry);
    }
    return [updateDoc(passDoc, passUpdate), !!passType.multiuse];
  };
  const [passPromise, pass_is_multiuse] = getPassUpdate();

  const attendancePath = getCollectionPath(props, DOOR_PREFIX + "attendance");
  if (!attendancePath) {
    return Promise.reject("Need classId or eventId");
  }

  const date = props.prebook ? TimestampMax : Timestamp.now();

  const attendance: Attendance = {
    pass_name: props.pass.name,
    pass_code: props.pass.code,
    pass_is_multiuse,
    is_present: !props.prebook,
    date,
    event_ref: getCollectionDoc(props) as DocumentReference<ClassNight>,
    person: castDoc(DOOR_PREFIX + "person", props.personId as string),
    pass: passDoc,
  };
  const attendancePromise = setDoc(
    doc(attendancePath, props.personId),
    attendance as object,
    { merge: true }
  );
  return Promise.allSettled([passPromise, attendancePromise])
    .then(() => Promise.resolve(true))
    .catch((reason) => Promise.reject(reason));
};

export const removeAttendance = (props: PassSalePropsBase): Promise<void> => {
  const attendancePath = getCollectionPath(props, DOOR_PREFIX + "attendance");
  if (!attendancePath) {
    return Promise.reject("Need classId or eventId");
  }

  return deleteDoc(doc(attendancePath, props.personId));
};

export const unUsePass = (props: UsePassProps): Promise<boolean> => {
  decrementAttendance(props.personId as string);
  const passDoc: DocumentReference<Pass> = castDoc(
    props.pass.collectionPath as string
  );

  const getPassUpdate = (): Promise<void> => {
    if (!("remaining_uses" in props.pass)) {
      return Promise.resolve();
    }
    const passUpdate: Partial<Pass> = {
      remaining_uses: props.pass.remaining_uses + 1,
    };
    passUpdate.has_remaining_uses = true;
    if (!props.pass.type?.path) {
      return Promise.reject("Pass type not recognised");
    }
    return updateDoc(passDoc, passUpdate);
  };
  const passPromise = getPassUpdate();

  const attendancePromise = removeAttendance(props);
  return Promise.allSettled([passPromise, attendancePromise])
    .then(() => Promise.resolve(true))
    .catch((reason) => Promise.reject(reason));
};

export const usePrebookedPass = (
  props: UsePrebookedPassProps
): Promise<boolean> => {
  clearScanRecord(props);
  incrementAttendance(props.personId as string);

  const attendanceRef = getCollectionDoc(
    props,
    "attendance",
    props.attendance.id as string
  );
  if (attendanceRef) {
    updateDoc(attendanceRef, { is_present: true, date: Timestamp.now() });
  }

  return Promise.resolve(true);
};

export const unUsePrebookedPass = (
  props: UsePrebookedPassProps
): Promise<boolean> => {
  clearScanRecord(props);
  incrementAttendance(props.personId as string);

  const attendanceRef = getCollectionDoc(
    props,
    "attendance",
    props.attendance.id as string
  );
  if (attendanceRef) {
    updateDoc(attendanceRef, { is_present: false });
  }

  return Promise.resolve(true);
};

export const buyPass = (props: BuyPassProps): Promise<Pass> => {
  if (!props.pass.collectionPath) {
    alert("Error buying pass, pass path missing");
    console.log(props);
    return Promise.reject();
  }

  const passPath = DOOR_PREFIX + `person/${props.personId}/passes/`;
  const remaining_uses =
    "valid_classes" in props.pass ? props.pass.valid_classes || 1 : 0;
  const pass: Pass | AvailableEventPrice = {
    code: props.pass.code,
    name: props.pass.name,
    type: castDoc(props.pass.collectionPath),
    remaining_uses,
    has_remaining_uses: true,
    purchased: Timestamp.now(),
    expires: TimestampMax,
  };
  return addDoc(collection(db, passPath), pass).then((result) => {
    const saleLine: SaleLine = {
      quantity: 1,
      item: castDoc(props.pass.collectionPath as string),
      name: props.pass.name,
      pass: result as DocumentReference<Pass>,
    };
    if (props.sale) {
      if (props.pass.price) {
        props.sale.amount += props.pass.price;
      }
      if (props.pass.collectionPath) {
        props.sale.lines.push(saleLine);
      }
    } else {
      const sale: Sale = {
        method: props.saleMethod || "cash",
        amount: props.pass.price || 0,
        lines: [saleLine],
        time: Timestamp.now(),
      };
      addDoc(collection(db, DOOR_PREFIX + "sale"), sale).then((saleResult) => {
        updateDoc(doc(db, result.path), { sale: saleResult });
      });
    }
    return { ...pass, collectionPath: result.path };
  });
};

export const refundSale = (props: { sale: Sale }) => {
  const passes = Array.from(
    new Set(
      props.sale.lines
        .filter((line) => line.pass)
        .map((line) => line.pass?.path) as Exclude<
        SaleLine["pass"],
        undefined
      >["path"][]
    )
  ).map((path) => doc(db, path));

  const saleLines = props.sale.lines.map((line) =>
    Object.fromEntries(Object.entries(line).filter(([k]) => k !== "pass"))
  );

  return getDocs(
    query(collectionGroup(db, "attendance"), where("pass", "in", passes))
  ).then((snapshot) => {
    if (snapshot.docs.length > 10) {
      return Promise.reject(
        "Got too many attendance entries for this pass?\n" +
          snapshot.docs.map((doc) => doc.ref.path).join()
      );
    }

    const attendanceUpdates = snapshot.docs.map((attendance) =>
      updateDoc(attendance.ref, {
        pass: null,
        pass_code: deleteField(),
        pass_name: deleteField(),
        pass_is_multiuse: false,
      })
    );

    const salePromise = updateDoc(
      doc(db, props.sale.collectionPath as string),
      { refunded: true, lines: saleLines }
    );

    const passPromise = passes.map((pass) => deleteDoc(doc(db, pass.path)));

    const personUpdateTimePromise = () => {
      if (props.sale.person) {
        updateDoc(props.sale.person, { last_update: Timestamp.now() });
      }
    };

    return Promise.allSettled([
      attendanceUpdates,
      salePromise,
      passPromise,
      personUpdateTimePromise,
    ]);
  });
};

export const buyUsePass = (props: BuyPassProps): Promise<boolean> => {
  const prebook = !!(
    props.forEvent && props.forEvent !== (props.classId || props.eventId)
  );
  return new Promise((resolve, reject) => {
    buyPass(props).then((result) => {
      usePass({ prebook, ...props, pass: result })
        .then(resolve)
        .catch(reject);
    });
  });
};

export const applyPendingEntries = (props: ApplyPendingProps) => {
  const saleBaseProps: PassSalePropsBase = {
    personId: props.personId,
    classId: props.classId,
    eventId: props.eventId,
    passTypes: props.passTypes,
    forEvent: props.forEvent,
  };
  const sale: Sale = {
    method: props.saleMethod,
    amount: 0,
    lines: [],
    time: Timestamp.now(),
  };
  if (props.classId) {
    sale.event = castDoc("class-nights", props.classId);
  } else if (props.eventId) {
    sale.event = castDoc("events", props.eventId);
  }
  if (props.personId) {
    sale.person = castDoc(DOOR_PREFIX + "person", props.personId);
  }

  const entryPromises: Promise<
    DocumentReference<DocumentData> | Pass | boolean
  >[] = props.pending.map((entry) => {
    if (entry.committing) {
      return Promise.reject("Save already in progress");
    }
    if (["buy", "buyUse"].includes(entry.action)) {
      return (entry.action === "buy" ? buyPass : buyUsePass)({
        ...saleBaseProps,
        forEvent: entry.forEvent,
        sale,
        pass: (entry.passType as PassType) || entry.eventPrice,
      });
    } else if (entry.action === "checkIn") {
      return usePrebookedPass({
        ...saleBaseProps,
        attendance: entry.attendance as Attendance,
      });
    } else if (entry.action === "use") {
      return usePass({
        ...saleBaseProps,
        forEvent: entry.forEvent,
        pass: entry.pass as Pass,
      });
    } else if (entry.action === "unpaid") {
      return unpaidAttendance(saleBaseProps);
    } else {
      return Promise.reject("unmatched action " + entry.action);
    }
  });

  return Promise.allSettled(entryPromises).then((entryPromiseResults) => {
    if (sale.amount > 0) {
      return addDoc(collection(db, DOOR_PREFIX + "sale"), sale).then(
        () => entryPromiseResults
      );
    }
    return entryPromiseResults;
  });
};

export const getFreePassForCategory = (
  category: NonNullable<Person["category"]>,
  personCategories: Record<string, PersonCategory>,
  passTypes: Record<string, PassType>
) => {
  const catPath =
    typeof category === "string"
      ? category
      : "path" in category
      ? category.path
      : undefined;
  if (catPath && catPath in personCategories) {
    const category = personCategories[catPath];

    if (category?.freeEntry && passTypes) {
      const passes = Object.values(passTypes).filter(
        (passType) =>
          passType.valid_category?.path === category.collectionPath &&
          !passType.price
      );
      if (passes.length === 1) {
        return passes[0];
      }
    }
  }
};
