import { Timestamp, DocumentReference, DocumentData } from "firebase/firestore";

export type FirebaseValueType =
  | Timestamp
  | DocumentReference
  | string
  | number
  | boolean
  | undefined
  | string[]
  | number[]
  | object[];
export interface FirebaseBackedObject {
  id?: string;
  name?: string;
  collectionPath?: string;
}

export interface ActiveFirebaseBackedObject extends FirebaseBackedObject {
  active?: boolean;
}

export interface FirebaseBackedSearchableObject extends FirebaseBackedObject {
  searchKeywords: string[];
}

export interface PersonCategory extends ActiveFirebaseBackedObject {
  description?: string;
  restricted: boolean;
  freeEntry?: boolean;
  concessionRate?: boolean;
  isStaff?: boolean;
  nonParticipant?: boolean;
}

export const PaymentMethods = {
  // Should be shown in this order
  cash: "Cash",
  square: "Square",
  internet: "Internet EFT",
  voucher: "Voucher",
} as const;

export interface Photo {
  url: URL;
  description?: string;
}

export interface Location extends ActiveFirebaseBackedObject {
  address: string;
  map_link?: URL;
  description?: string;
  photos?: Photo[];
}

export interface PersonSource extends ActiveFirebaseBackedObject {
  source_discount: boolean;
}

export interface Person extends FirebaseBackedSearchableObject {
  /*
  For mailchimp export:
  * email
  * first name
  * last name
  * 
  * category
  * recent
  * lastupdate
  * passalert
  * passnotes
  * reminders
  */
  // full_name: string; FirebaseBackedObject comes with a name
  other_names?: string;
  dob?: Timestamp;
  phone?: string;
  postal_address?: string;
  suburb?: string;
  postcode?: string;
  email?: string;
  signed?: Timestamp;
  minor_signed?: boolean;
  source?: DocumentReference<PersonSource> | PersonSource;
  category?: DocumentReference<PersonCategory> | PersonCategory;
  attendances?: number;
  barcode?: string;
  last_update?: Timestamp;
  last_attended?: Timestamp;
  photo_permission?: boolean;
  pronoun?: string;
  used_source_discount?: boolean;
}

export interface Stock extends FirebaseBackedSearchableObject {
  price: number | undefined; // e.g. a voucher can be for any value they like
  barcode?: string;
  active?: boolean;
}

interface PassTypeBase {
  // when pass is created, copy the name&code over since most of the time that's all that's needed
  name?: string;
  code: string;
}

export interface PassType extends PassTypeBase, ActiveFirebaseBackedObject {
  price: number | undefined; // event pass will pick up price from the event
  valid_classes?: number;
  valid_month?: number;
  valid_week?: number;
  multiuse?: boolean;
  term?: boolean;
  max_attendances?: number;
  is_event?: boolean;
  valid_category?: DocumentReference<PersonCategory>;
  presaleable: boolean;
}

export interface Pass extends PassTypeBase, FirebaseBackedObject {
  type: DocumentReference<PassType> | null;
  remaining_uses: number;
  has_remaining_uses: boolean; // for firestore indexing requirements
  expires: Timestamp;
  term?: DocumentReference<Term> | null;
  purchased?: Timestamp;
  sale?: DocumentReference<Sale>;
}

interface AttendanceBase extends FirebaseBackedObject {
  pass_name?: string;
  pass_code?: string;
  pass_is_multiuse?: boolean;
  is_present: boolean;
  date: Timestamp;
  event_ref?: DocumentReference<ClassNight>;
}
export interface Attendance extends AttendanceBase {
  person: DocumentReference<Person>;
  pass?: DocumentReference<Pass> | null;
}

export interface AttendanceEntry extends AttendanceBase {
  person: Person;
  personCategory?: PersonCategory;
  pass?: Pass | DocumentReference<Pass>;
}

export interface AttendanceSummary {
  recent: Attendance[];
  // TODO: each year sum up classes/workshops/parties
  counts?: Record<string, Record<string, number>>;
}

export interface Discount extends ActiveFirebaseBackedObject {
  // need an admin page as well
  discount_flat?: number;
  discount_mult?: number;
  valid_limit?: number;
  valid_source?: DocumentReference<PersonSource>;
  max_attendances?: number;
  min_attendance_gap_months?: number;
  max_attendance_gap_months?: number;
}

export type SaleLine = {
  quantity: number;
  item: DocumentReference<Stock | PassType | AvailableEventPrice>;
  // if it's a Pass/EventPrice, also record entry of the pass instance
  pass?: DocumentReference<Pass | AvailableEventPrice>;
  name?: string;
};

// TODO: allow sale upgrade (e.g. single to starter's pass) - replaces previous sale?
export interface Sale extends FirebaseBackedObject {
  lines: SaleLine[];
  person?: DocumentReference<Person>;
  amount: number;
  method: keyof typeof PaymentMethods;
  event?: DocumentReference<InternalEventBase>;
  time: Timestamp;
  notes?: string; // e.g. if a discount was applied, or the amount on a voucher, or if someone provides an EFT reference
  refunded?: boolean;
}

export interface CurriculumEntry {
  beginner?: string;
  fundamentals?: string;
  variations?: string;
}

export interface Curriculum extends ActiveFirebaseBackedObject {
  nights?: CurriculumEntry[];
}

export type Term = FirebaseBackedObject;

interface EventBase extends FirebaseBackedObject {
  start: Timestamp;
  end?: Timestamp;
}

interface InternalEventBase extends EventBase {
  location: DocumentReference<Location>;
  term?: DocumentReference<Term>;
  bookable: boolean;
  attendees: Attendance[];
  sales: Sale[];
}

export type Playlist = [name: string, url: URL];

export interface EventPrice extends FirebaseBackedObject {
  name: string;
  code: string;
  available_until: Timestamp;
  full_price: number;
  concession?: number;
}

export interface AvailableEventPrice extends EventPrice {
  // after filtering to those that are still available, and the full/concession price selected
  price: number;
}

export interface CashBoxMixin {
  cashbox?: number;
  notes?: string;
}

export interface DanceEvent extends InternalEventBase, CashBoxMixin {
  is_published: boolean;
  playlist?: string;
  short_description?: string;
  description: string;
  prices?: EventPrice[];
}

export interface ExternalEvent extends EventBase {
  is_published: boolean;
  location: string;
  short_description?: string;
  description: string;
}

export const ClassLevels = ["Beginner", "Fundamentals", "Variations"];
export const Freestyles = ["Beginner", "Intermediate"];

export interface ClassNight extends FirebaseBackedObject, CashBoxMixin {
  date: Timestamp;
  location: DocumentReference<Location>;
  // teaching?: Record<ClassLevel, string>;
  // taught?: Record<ClassLevel, string>;
  teachingBeginner: string;
  teachingFundamentals: string;
  teachingVariations: string;
  taughtBeginner?: string;
  taughtFundamentals?: string;
  taughtVariations?: string;
  variationsVideo?: string;
  beginnerPlaylist?: string;
  intermediatePlaylist?: string;
  curriculum?: DocumentReference<Curriculum>;
  curriculumOffset?: number;
  // playlists?: Record<Freestyle, string>;
}

export type docLookup = Record<string, DocumentData>;
export type CategoryLookup = Record<string, PersonCategory>;

export interface BarcodeScan extends FirebaseBackedObject {
  // id: person's id
  // name: person's name
  passName?: string; // undefined means not yet signed in
  time: Timestamp;
  acknowledged: boolean;
}

export interface Notes extends FirebaseBackedObject {
  text: string;
}
