import { Timestamp, orderBy } from "firebase/firestore";
import { FormPageOptions } from "../table";
import { Person } from "../../../types";

export const PersonFormDefinition: FormPageOptions = {
  formFields: [
    ["Name", "name"],
    ["Other Names", "other_names"],
    ["Date of Birth", "dob"],
    ["Minor Signed", "minor_signed"],
    ["Category", "category"],
    ["Pronoun", "pronoun"],
    ["Address", "postal_address"],
    ["Suburb", "suburb"],
    ["Postcode", "postcode"],
    ["Phone", "phone"],
    ["Email", "email"],
    ["Source", "source"],
    ["Photo Permission", "photo_permission"],
    ["Form Signed", "signed"],
    ["Barcode", "barcode"],
    ["Last Attended", "last_attended"],
    ["Attendances", "attendances"],
  ],

  formTypes: {
    signed: "date",
    dob: "date",
    minor_signed: "boolean",
    photo_permission: "boolean",
    source: "sources",
    category: "categories",
    last_attended: "date",
    email: "email",
    phone: "tel",
    attendances: "number",
  },

  formDefaults: {
    last_attended: Timestamp.now(),
    signed: Timestamp.now(),
  },

  canDelete: false,
  requiredFields: ["name", "category", "last_attended", "signed"],
  displayOrder: [orderBy("last_attended", "desc")],

  searchIndexFields: ["name", "other_names"],

  dbCollection: "person",
  prepareSave: (person: object) => {
    const barcode = (person as Person).barcode;
    return {
      last_attended: Timestamp.fromMillis(0), // must exist in firestore since we order by this
      ...person,
      ...(barcode ? { barcode: barcode.toLocaleUpperCase() } : {}),
      last_update: Timestamp.now(),
    } as object;
  },
};

export const PublicPersonFormDefinition: FormPageOptions = {
  ...PersonFormDefinition,
  formFields: [
    ["Name", "name"],
    ["Other Names", "other_names"],
    ["Phone", "phone"],
    ["Address", "postal_address"],
    ["Postcode", "postcode"],
    ["Suburb", "suburb"],
    ["Email", "email"],
    ["Details Updated", "signed"],
    ["Minor", "minor_signed"],
    ["Source", "source"],
    ["Category", "category"],
    ["Attendances", "attendances"],
    ["Photo/Video Permission", "photo_permission"],
    ["Pronoun", "pronoun"],
  ],
};
