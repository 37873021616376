import FormPage, { FormTab } from "../../../../components/forms/table";
import {
  AdminAttendance,
  AdminAttendanceLocations,
  AdminAttendanceSales,
  AdminAttendanceSources,
  AdminAttendanceTimes,
} from "./attendance_base";
import { AdminClassesDefinition } from "./classes";

export default function AdminClassSalesAttendance() {
  const tabs: FormTab = {
    Summary: null,
    Sales: AdminAttendanceSales(),
    Attendance: AdminAttendance(),
    "Arrival Times": AdminAttendanceTimes(),
    Sources: AdminAttendanceSources(),
    "Person Locations": AdminAttendanceLocations(),
  };

  const pageDefinition = {
    ...AdminClassesDefinition(),
    canDelete: false,
    list: true,
    tabs,
  };

  return FormPage(pageDefinition);
}
