import { OfflineBanner } from "../../../components/offline_banner";
import BarcodeScanner from "../../../components/barcode_scanner";
import style from "../style.scss";

export default function DoorPublic() {
  return (
    <>
      <div class="app-body">
        {document.fullscreenEnabled && (
          <div class={style.fullscreenButton}>
            <span
              title="Fullscreen"
              onClick={() =>
                document.fullscreenElement
                  ? document.exitFullscreen()
                  : document
                      .getElementsByClassName("app-body")[0]
                      .requestFullscreen()
              }
            >
              ⛶
            </span>
          </div>
        )}
        <BarcodeScanner />
      </div>
      <OfflineBanner />
    </>
  );
}
