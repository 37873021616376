import { RouteEntry, RouteSet } from "src/types";
import AdminAnnouncements from "./components/announcements";
import AdminClassLocations from "./components/classes/locations";
import AdminClassTerms from "./components/classes/terms";
import AdminMedia from "./components/media";
import AdminPages from "./components/pages";
import AdminClasses from "./components/classes/classes";
import AdminSources from "./components/people/sources";
import AdminCategories from "./components/people/categories";
import AdminPerson from "./components/people/person";
import AdminStock from "./components/stock";
import AdminPassTypes from "./components/people/pass_types";
import AdminImport from "./components/import";
import Mailchimp from "./components/mailchimp";
import AdminActivePasses from "./components/people/active_passes";
import AdminEvents from "./components/classes/events";
import AdminClassSalesAttendance from "./components/classes/class_attendance";
import AdminEventSalesAttendance from "./components/classes/event_attendance";
import AdminExternalEvents from "./components/classes/external_events";
import AdminClassRoster from "./components/classes/roster";
import AdminCurriculum from "./components/classes/curriculum";
import AdminReportClasses from "./components/reports/classes";
import AdminReportRecentAttendances from "./components/reports/recent_attendance";

const adminClassesRoutes: RouteSet = {
  ["classes"]: { title: "Classes", component: AdminClasses },
  ["class-attendance"]: {
    title: "Class Sales & Attendance",
    component: AdminClassSalesAttendance,
  },
  ["roster"]: { title: "Class Rostering", component: AdminClassRoster },
  ["events"]: { title: "Events", component: AdminEvents },
  ["event-attendance"]: {
    title: "Event Sales & Attendance",
    component: AdminEventSalesAttendance,
  },
  ["external-events"]: {
    title: "External Events",
    component: AdminExternalEvents,
  },
  ["terms"]: { title: "Terms", component: AdminClassTerms },
  ["curriculums"]: { title: "Curriculums", component: AdminCurriculum },
  ["locations"]: { title: "Locations", component: AdminClassLocations },
};

const adminPeopleRoutes: RouteSet = {
  ["active-passes"]: { title: "Active Passes", component: AdminActivePasses },
  ["pass-types"]: { title: "Pass Types", component: AdminPassTypes },
  ["sources"]: { title: "Sources", component: AdminSources },
  ["categories"]: { title: "Person Categories", component: AdminCategories },
  // ["discounts"]: { title: "Discounts", component: AdminDiscounts },
  // ["claims"]: { title: "Permissions", component: AdminTodo },
  ["person"]: { title: "Update Person", component: AdminPerson },
};

const adminReportsRoutes: RouteSet = {
  // unpaid report
  // sources summary
  ["classes"]: { title: "Classes & Events", component: AdminReportClasses },
  ["recent-attendances"]: {
    title: "Recent Attendances",
    component: AdminReportRecentAttendances,
  },
};

const adminRoutes: Record<string, RouteEntry> = {
  // add/edit/remove announcements
  ["announcements"]: { title: "Announcements", component: AdminAnnouncements },
  // edit list of pages on website
  ["pages"]: { title: "Pages", component: AdminPages },
  // view list of photos/etc in firestore
  ["media"]: { title: "Photos/Files/etc", component: AdminMedia },
  // class admin, add/edit classes, add/edit events
  ["classes"]: { title: "Classes & Events", subRoute: adminClassesRoutes },
  // person & pass admin
  ["people"]: { title: "People & Passes", subRoute: adminPeopleRoutes },
  ["reports"]: { title: "Reports", subRoute: adminReportsRoutes },
  // add/edit stock
  ["stock"]: { title: "Stock", component: AdminStock },
  // import data from old door system
  ["import"]: { title: "Import database", component: AdminImport },
  // see last sync of people & build newsletters
  ["mailchimp"]: { title: "Newsletters", component: Mailchimp },
  // [baseURL + ""]: { title: "", component: Admin },
};

export default adminRoutes;
