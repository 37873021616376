import { useEffect, useState } from "preact/hooks";

import style from "./style.scss";

export const OfflineBanner = () => {
  const [online, setOnline] = useState<boolean>(navigator.onLine);

  useEffect(() => {
    window.addEventListener("offline", () => setOnline(false));
    window.addEventListener("online", () => setOnline(true));
  }, []);

  return (
    <div class={online ? style.online : style.offline}>
      Lost connection to server
    </div>
  );
};
